@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
    
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #0063124d;
  background: #edf2f7;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border: 3px solid #edf2f7;
  background: #999999;
  border-radius: 10px;
  transition: background-color cubic-bezier(0.165, 0.84, 0.44, 1);
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555555;
}