.App {
    font-family: sans-serif;
    text-align: center;
}

.question-section {
    padding: 1em;
    background: #f1f1f1;
    border-radius: 8px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    cursor: pointer;
    width: 60vw;
    color: #013d00;
}

.question-style {
    width: 90%;
    font-weight: 600;
    margin:0 8px;
    padding: 2px 0;
}

.question-align {
    align-items: center;
    display: flex;
    min-height: 10px;
    text-align: left;
}

.question-styling {
    font-size: 17px;
    width: 100%;
    color: #0d551f;
}

.question-icon {
    background: #00BF61;
    border-radius: 50%;
    color: #fff;
    border: none;
    font-weight: 600;
    cursor: pointer;
    font-size: 22px;
    margin-right: 8px;
    padding: 2px 0;
}

.rotate {
    transform: rotate(45deg);
}

.answer-divider {
    border-top: 1px solid lightgray;
}

.answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.6s ease;
    text-align: left;
}

.answer>p {
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 0.85em;
    line-height: 1.75em;
}
  
@media screen and (max-width: 640px) {
    .question-section {
        width: 70vw;
        padding: 0.5em;
        font-size: 1em;
    }
    .question-style {
        font-size: 0.85em;
    }
    .question-icon {
        width: 14px;
        height: 14px;
    }
}